// src/components/TermsOfUse.js
import React from 'react';
import './TermsOfUse.css'; // Assure-toi que ce fichier CSS existe

const TermsOfUse = () => {
  return (
    <div className="terms-of-use">
      <h1>Conditions d'Utilisation</h1>
      <p>
        Bienvenue sur notre site. En utilisant nos services, vous acceptez de respecter les conditions d'utilisation suivantes.
      </p>

      <h2>1. Acceptation des conditions</h2>
      <p>
        En accédant à ce site, vous acceptez d'être lié par ces conditions d'utilisation. Si vous n'êtes pas d'accord avec l'une des parties de ces conditions, vous ne devez pas utiliser notre site.
      </p>

      <h2>2. Modifications des conditions</h2>
      <p>
        Nous nous réservons le droit de modifier ces conditions à tout moment. Toute modification sera effective immédiatement dès sa publication sur le site. Il est de votre responsabilité de consulter régulièrement les conditions d'utilisation pour rester informé des changements.
      </p>

      <h2>3. Utilisation du site</h2>
      <p>
        Vous vous engagez à utiliser ce site uniquement à des fins légales et d'une manière qui ne porte pas atteinte aux droits d'autrui ou qui ne restreint ni n'entrave l'utilisation de ce site par des tiers.
      </p>

      <h2>4. Propriété intellectuelle</h2>
      <p>
        Tous les contenus présents sur ce site, y compris les textes, images, logos et graphiques, sont la propriété de [Nom de l'entreprise] et sont protégés par les lois sur la propriété intellectuelle.
      </p>

      <h2>5. Limitation de responsabilité</h2>
      <p>
        [Nom de l'entreprise] ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'incapacité à utiliser ce site.
      </p>

      <h2>6. Liens vers des sites tiers</h2>
      <p>
        Ce site peut contenir des liens vers d'autres sites. Nous ne sommes pas responsables du contenu de ces sites et ne cautionnons pas les informations qui y sont publiées.
      </p>

      <h2>7. Droit applicable</h2>
      <p>
        Ces conditions sont régies par les lois en vigueur dans [votre pays]. Tout litige relatif à l'utilisation de ce site sera soumis à la compétence des tribunaux de [votre ville ou région].
      </p>

      <h2>8. Contact</h2>
      <p>
        Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse suivante : [adresse e-mail de contact].
      </p>
    </div>
  );
};

export default TermsOfUse;
