// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ scrollToWhyChoose, scrollToOffers }) => {
  const [menuActive, setMenuActive] = useState(false); // État pour gérer l'affichage du menu

  const toggleMenu = () => {
    setMenuActive(!menuActive); // Inverse l'état
  };

  return (
    <nav>
      <div className="container nav-container">
        <Link to="/" className="logo">SOSBox</Link>
        <div className="nav-phone phone-number">09 72 46 20 69</div> {/* Ajout de la classe phone-number */}
        <div className="nav-toggle" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`nav-menu ${menuActive ? 'active' : ''}`}>
          <li><a href="#why-choose" onClick={scrollToWhyChoose}>Pourquoi choisir un SOSBox</a></li>
          <li><a href="#offers" onClick={scrollToOffers}>Offres et tarifs</a></li>
          <li><Link to="/contact">Contactez-nous</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
