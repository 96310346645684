// Dans Testimonials.js
import React from 'react';
import './Testimonials.css'; // Importation du fichier CSS

const Testimonials = () => {
  const testimonials = [
    { name: 'Jean D.', comment: 'Service impeccable et box très propre !', rating: 5 },
    { name: 'Marie L.', comment: 'Flexibilité appréciée pour mon déménagement.', rating: 4 },
    { name: 'Pierre M.', comment: 'Sécurité au top, je recommande !', rating: 5 },
  ];

  const renderStars = (rating) => {
    return '★'.repeat(rating) + '☆'.repeat(5 - rating);
  };

  return (
    <section className="testimonials">
      <div className="container">
        <h2 className="testimonials-title">Ce que disent nos clients</h2> {/* Ajout de la classe ici */}
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p>{testimonial.comment}</p>
              <p className="testimonial-author">{testimonial.name}</p>
              <div className="rating">{renderStars(testimonial.rating)}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
