// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Assure-toi que ce fichier CSS existe

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Politique de Confidentialité</h1>
      <p>
        Votre confidentialité est importante pour nous. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations.
      </p>
      <h2>Informations que nous collectons</h2>
      <p>
        Nous collectons des informations personnelles lorsque vous utilisez notre service, notamment votre nom, adresse e-mail et informations de paiement.
      </p>
      <h2>Utilisation de vos informations</h2>
      <p>
        Nous utilisons vos informations pour vous fournir nos services, communiquer avec vous et améliorer notre site web.
      </p>
      <h2>Protection de vos informations</h2>
      <p>
        Nous prenons des mesures de sécurité pour protéger vos informations personnelles contre l'accès non autorisé.
      </p>
      <h2>Modifications de cette politique</h2>
      <p>
        Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons des modifications en publiant la nouvelle politique sur cette page.
      </p>
      <h2>Nous contacter</h2>
      <p>
        Si vous avez des questions concernant cette politique de confidentialité, n'hésitez pas à nous contacter.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
